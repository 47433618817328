import React from 'react'
import line from './line.png'
import pc from './pc.png'

function When () {
    return (
        <div className="card">
            <div className="card-header">
                <a className="a-large" name="when">When it all started...</a>
                <img src={line} className="line" alt=""/>
                <img src={pc} className="pc" alt=""/> Software Developer Intern for Pierce County IT -- 2/2019 - 8/2019
                <p>Six months as a full stack developer working on AngularJS web applications. Front-end development using JavasScript, HTML, CSS and working within accessibility guidelines for public facing County web tools. Back-end web services developed using Groovy, Apache Maven and RESTful APIs with heavy authentication and security considerations. Developed an internal OfferUp-style employee forum for selling and purchasing personal items. Designed APIs, database tables, created all search functionality utilizing API requests to a database, constructed unique front-end components such as a card style layout. Responsible for meeting with UX designer and project manager to create an application within the scope of the project that satisfied the client. </p>
            </div>

        </div>


    );
}


export default When;