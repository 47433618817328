import './App.css';
import Nav from './Nav';
import Footer from "./Footer";
import Greeting from "./Greeting";
import Who from './Who';
import What from './What';
import When from './When';
import Where from './Where';
import Why from './Why';


function App() {
  return (
    <div className="App">
      <Nav />
      <Greeting />
      <Who />
      <What />
      <When />
      <Where />
      <Why />
      <Footer />
    </div>
  );
}
export default App;
