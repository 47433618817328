import React from 'react'
import pic from './main_pic.jpg';
import useWindowSize from './useWindowSize';

function Greeting () {

    const {width} = useWindowSize();

    return(

        <div className="Greeting">
            <div className="greet-cont">
                <div className="greet-grid-cont">
                    {width <= 945 && (
                        <>
                            <div className="left-small">
                                <img src={pic} className="greet-pic" alt="miranda"/>
                            </div>
                        </>
                    ) }
                    {width > 945 && (
                        <>
                            <div className="left-large">
                                <img src={pic} className="greet-pic" alt="miranda"/>
                            </div>
                        </>
                    )}

                    <div className="right"> 
                        <h1>Hello,</h1>
                        <div className="name-grid">
                            <div className="left-name">
                                my name is
                            </div>
                            <div className="right-name">
                                Miranda Bessex.
                            </div>
                        </div>
                        <div className="bio">
                        I am a full-stack software developer.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Greeting;