import React from 'react'
import pic from './main_pic.jpg';
import useWindowSize from './useWindowSize';

function Nav () {

    const {width} = useWindowSize();


    return(

        <div className="Nav">

            <div className="nav-cont">

                {width <= 945 && (
                    <>
                    <div className="nav-grid-cont-small">
                        <div className="nav-button">
                            <a href="#who">Who</a>
                        </div>
                        <div className="nav-button">
                            <a href="#what">What</a>
                        </div>
                        <div className="nav-button">
                            <a href="#when">When</a>
                        </div>
                        <div className="nav-button">
                            <a href="#where">Where</a>   
                        </div>
                        <div className="nav-button">
                            <a href="#why">Why</a>
                        </div>
                    </div>
                    </>
                ) } 
                {width > 945 && (
                    <>
                    <div className="nav-grid-cont-large">
                        <div className="nav-button">
                            <a href="#who">Who</a>
                        </div>
                        <div className="nav-button">
                            <a href="#what">What</a>
                        </div>
                        <div className="nav-button">
                            <a href="#when">When</a>
                        </div>
                        <div className="nav-button">
                            <a href="#where">Where</a>   
                        </div>
                        <div className="nav-button">
                            <a href="#why">Why</a>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Nav;
