import React from 'react'
import line from './line.png'

function Who () {
    return (
        <div className="card">
            <div className="card-header">
                <a className="a-large" name="who">Who I am...</a>
                <img src={line} className="line" alt=""/>
                <p>Like I said, my name is Miranda. I am a recent graduate from the 
                    University of Washington with my Masters degree in Computer Science and Systems. 
                    I am passionate about software development with hopes of one day creating software capable of 
                    possitivley impacting millions of lives. 
                    I am a fast learner and a problem solver. Let's chat about how we can build technology together
                    to better peoples lives. 
                    
                    
                     </p>
            </div>

        </div>


    );
}


export default Who;