import React from 'react'
import line from './line.png'

function Where () {
    return (
        <div className="card">
            <div className="card-header">
                <a className="a-large" name="where">Where I went to school...</a>
                <img src={line} className="line" alt=""/>
                <p>University of Washington Tacoma -- BS Bachelors of Science -- Computer Science and Systems -- 2016-2020</p>
                <p>University of Washington Tacoma -- MSCS Masters of Science Computer Science and Systems -- 2020-2021</p>
            </div>

        </div>


    );
}


export default Where;