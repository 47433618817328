import React from 'react'
import logo from './logo.svg';


function Footer () {
    return(
        <div class="react-grid">
            <div class="react-right">
                <img src={logo} className="App-logo" alt="logo" class="react-logo"/>
            </div>
            <div class="react-left">
                I built this application using React and deployed it with Firebase.
            </div>
        </div>
    )
}

export default Footer;