import React from 'react'
import growbotlogo from './growbot.jpg'
import securelogo from './secureaccess.png'
import line from './line.png'
import archgb from './archgb.png'
import arch1 from './arch1.png'

function What () {
    return (
        <div className="card">
            <div className="card-header">
                <a className="a-large" name="what">What I have created...</a>
                <img src={line} className="line" alt=""/>
                <p></p>
                <div className="growbot-img-cont">
                    <img src={growbotlogo} alt="growbot logo" height="300px"/>
                    <img src={archgb} alt="growbot archetecture design" height="300px"/>
                </div>
                <p>Using GrovePi, RaspberryPi, Grove environmental sensors, actuators, and Node Red, GrowBot IoT Gardening System was designed to meet four major design goals; Monitoring the environment, automated upkeep of the system, status visualization, and system management. Sensors monitored the environment and relayed the data to a InfluxDB time series database, this data was visualized using a Grafana Dashboard.  The system regularly queried the data to look for thresholds within the environment. If these thresholds were not met, the system triggered actuators to provide water and light to the environment. IBM Watson APIs were used to allow for voice commands and vocal status alerts. Utilization of both Cloud-based and Edge based tools were required. </p>
                <div className="secure-img-cont">
                    <img src={securelogo} alt="secure access logo" height="200px"/>
                    <img src={arch1} alt="secure archetecture design" height="300px"/>
                </div>
                <p>Web service based RFID residential and commercial access solution. The system consisted of an extensive web API providing many RESTful web services, a database, and a front-end interface. The web API, written in C#, featured various returned data types, protocols and composite service functions. RESTful web services were used for tasks such as authentication and SMS alerts. This web API simulated the collection and storage of real time data in a MySQL database. A front-end interface was created using HTML, CSS, and PHP to query access records and user data. The project was deployed using an Apache web server on a Google Cloud Platform LAMP stack virtual machine. </p>
            </div>

        </div>


    );
}


export default What;