import React from 'react'
import line from './line.png'

function Why () {
    return (
        <div className="card">
            <div className="card-header">
                <a className="a-large" name="why">Why you should hire me.</a>
                <img src={line} className="line" alt=""/>
                <p>I've been lucky enough to gain expirience in many different facets of computer
                     science in my years both at school and as an intern. But my skills listed above are just some advantages to having me
                     on your team. I am also friendly, determined and excited to learn. You can find me on Linkedin, or send me an email at mbessex@uw.edu. 
                     Hope to hear from you soon!
                     </p>
                
            </div>

        </div>


    );
}


export default Why;